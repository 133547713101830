import { createMemo } from 'solid-js'

import { createMappedState } from '#/utils/module/createMappedState'

import { createAccessTokenRefreshTimer } from './createAccessTokenRefreshTimer'
import { createAuthHandlers } from './createAuthHandlers'
import { createTokenStore } from './createTokenStore'

export function createAuthContext() {
  const [tokenState, setTokenState] = createTokenStore()

  const { refreshAccessToken, ...authHandlers } = createAuthHandlers([
    tokenState,
    setTokenState,
  ])

  createAccessTokenRefreshTimer({ tokenState, refreshAccessToken })

  const contextValue = createMappedState(tokenState, (state) =>
    // eslint-disable-next-line solid/reactivity
    createMemo(() => ({
      ...state,
      isLoggedOut: state.accessToken === undefined,
      isLoggedIn: !!state.accessToken,
      refreshAccessToken,
      ...authHandlers,
    })),
  )

  return contextValue
}
