import type { JSX } from 'solid-js'

import { Dialog } from '#/components/Dialog/Dialog'

import { useAuth } from './AuthProvider'
import { SignInWithAppleButton } from './SignInWithAppleButton'
import { SignInWithGoogleButton } from './SignInWithGoogleButton'

const AuthGuard = (props: { children: JSX.Element }): JSX.Element => {
  const auth = useAuth()

  return (
    <>
      <Dialog
        isLoading={auth.isLoading}
        isOpen={!auth.isLoggedIn || auth.isLoading}
        title={'Login'}
        content={
          <>
            <SignInWithAppleButton />
            <SignInWithGoogleButton />
          </>
        }
      />

      {props.children}
    </>
  )
}

export { AuthGuard }
