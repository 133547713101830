import './style.css'

import type { JSX } from 'solid-js'
import { Show } from 'solid-js'

import { AuthGuard } from '#/auth/ui/AuthGuard'
import { AuthProvider } from '#/auth/ui/AuthProvider'
import { clientEnv } from '#/clientEnv'
import { DialogProvider } from '#/components/Dialog/Dialog'
import { SubscriptionProvider } from '#/subscription/state/SubscriptionContext'

import { Flex } from '../components/ui/Flex'

export default function LayoutDefault(props: { children?: JSX.Element }) {
  return (
    <Show
      when={clientEnv.STACK_NAME === 'production'}
      fallback={
        <AuthProvider>
          <DialogProvider>
            <SubscriptionProvider>
              <AuthGuard>
                <Flex fullScreen>
                  <Content>{props.children}</Content>
                </Flex>
              </AuthGuard>
            </SubscriptionProvider>
          </DialogProvider>
        </AuthProvider>
      }
    >
      Coming soon...
    </Show>
  )
}

function Content(props: { children: JSX.Element }) {
  return <Flex flex={1}>{props.children}</Flex>
}
