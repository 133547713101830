import { createEffect, For, Match, Switch } from 'solid-js'
import { clientOnly } from 'vike-solid/clientOnly'

import { Button } from '#/components/ui/Button'

import {
  useSubscriptionActions,
  useSubscriptionState,
} from '../state/SubscriptionContext'

const LazyStripeCheckoutForm = clientOnly(() =>
  import('./StripeCheckoutForm').then(
    ({ StripeCheckoutForm }) => StripeCheckoutForm,
  ),
)

const SubscriptionDialogContent = (props: {
  onLoadingChange?: (isLoading: boolean) => void
}) => {
  const subscription = useSubscriptionState()
  const { startCheckout, setSelectedPlanId } = useSubscriptionActions()

  createEffect(() => {
    props.onLoadingChange?.(subscription.isLoading)
  })

  return (
    <Switch>
      <Match
        when={
          subscription.provider === 'stripe' &&
          !!subscription.clientSecret &&
          !!subscription.stripe && {
            ...subscription,
            stripe: subscription.stripe,
          }
        }
      >
        {(data) => <LazyStripeCheckoutForm {...data()} />}
      </Match>
      <Match when={subscription.plans}>
        {(plans) => (
          <div>
            <ul>
              <For each={plans()}>
                {(plan) => (
                  <li>
                    <label>
                      <input
                        type="radio"
                        checked={subscription.selectedPlanId === plan.id}
                        onClick={() => setSelectedPlanId(plan.id)}
                      />

                      <div>
                        {formatPrice(plan.amount, plan.currency)} /{' '}
                        {plan.intervalCount === 1
                          ? `${plan.interval}`
                          : `${plan.intervalCount} ${plan.interval}`}
                      </div>
                    </label>
                  </li>
                )}
              </For>
            </ul>
            <Button
              disabled={!subscription.selectedPlanId}
              onClick={() => {
                if (!subscription.selectedPlanId) return
                startCheckout()
              }}
            >
              Start subscription
            </Button>
          </div>
        )}
      </Match>
    </Switch>
  )
}

const formatPrice = (value: number, currency: string) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  }).format(value / 100)

export { SubscriptionDialogContent }
