import cookie from 'js-cookie'

import { accessTokenCookie, authCookiePaths } from '../../config'
import type { TokenStore } from './createTokenStore'

export function createHandleToken(setTokenState: TokenStore[1]) {
  return ({
    accessToken,
    expirationTime,
    issuedAt,
  }: {
    accessToken: string
    expirationTime: Date
    issuedAt: Date
  }) => {
    authCookiePaths.forEach((path) => {
      cookie.set(accessTokenCookie, accessToken, {
        domain: new URL(location.origin).hostname,
        path,
      })
    })

    setTokenState({
      accessToken,
      expirationTime,
      issuedAt,
      isLoading: false,
    })
  }
}
