import { Polymorphic } from '@kobalte/core'
import type { ParentComponent, ValidComponent } from 'solid-js'
import { createMemo, mergeProps, splitProps } from 'solid-js'

import type { BoxProps } from './Box'
import styles from './Flex.module.css'

type FlexProps<As extends ValidComponent = 'div'> = BoxProps<As> & {
  direction?: 'row' | 'column'
  flex?: number | true
  fullScreen?: boolean
  justifyContent?: 'start' | 'end' | 'center' | 'space-between'
  alignItems?: 'start' | 'end' | 'center'
}

const Flex: ParentComponent<FlexProps> = (props) => {
  const propsWithDefaults = mergeProps({ direction: 'row', flex: 0 }, props)
  const [ownProps, rest] = splitProps(propsWithDefaults, [
    'class',
    'classList',
    'direction',
    'flex',
    'style',
    'fullScreen',
    'justifyContent',
    'alignItems',
  ])

  const flex = createMemo(() => (ownProps.flex === true ? 1 : ownProps.flex))

  const hasFlexClass = createMemo(() => flex() > 0 && flex() <= 3)

  const style = createMemo(() => ({
    ...(flex() && !hasFlexClass() ? { flex: flex() } : {}),
    ...ownProps.style,
  }))

  return (
    <Polymorphic
      as={'div'}
      classList={{
        [styles.Flex]: true,
        ...(ownProps.class ? { [ownProps.class]: true } : {}),
        ...props.classList,
      }}
      data-direction={ownProps.direction}
      data-fullscreen={ownProps.fullScreen}
      data-flex={flex() ? flex() : undefined}
      data-align-items={ownProps.alignItems}
      data-justify-content={ownProps.justifyContent}
      style={style()}
      {...rest}
    />
  )
}

export { Flex }
