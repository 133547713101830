import { onMount } from 'solid-js'
import type { SetStoreFunction } from 'solid-js/store'

import { useAuth } from '#/auth/ui/AuthProvider'
import { trpc } from '#/trpc/client'

import { createConfirmApplePayment } from './createConfirmApplePayment'
import { createConfirmStripePayment } from './createConfirmStripePayment'
import { createHandleCheckoutSucceeded } from './createHandleCheckoutSucceeded'
import { createRequireSubscription } from './createRequireSubscription'
import { createStartCheckout } from './createStartCheckout'
import type { InitialSubscriptionState } from './initialState'
import type { SubscriptionState } from './mapSubscriptionState'

export const createSubscriptionActions = (
  state: SubscriptionState,
  setState: SetStoreFunction<InitialSubscriptionState>,
) => {
  const auth = useAuth()

  onMount(async () => {
    setState({ isSubscriptionLoading: true })

    await auth.waitForAuthentication()

    setState({ subscription: await trpc.getSubscriptionStatus.query() })

    setState({ isSubscriptionLoading: false })
  })

  const handleCheckoutSucceeded = createHandleCheckoutSucceeded(setState)

  const confirmApplePayment = createConfirmApplePayment(
    state,
    handleCheckoutSucceeded,
  )

  const startCheckout = createStartCheckout(
    state,
    setState,
    confirmApplePayment,
  )

  const setSelectedPlanId = (selectedPlanId: string) =>
    setState({ selectedPlanId })

  const confirmStripePayment = createConfirmStripePayment(
    state,
    setState,
    handleCheckoutSucceeded,
  )

  const requireSubscription = createRequireSubscription(state, setState)

  const setIsDialogOpen = (isDialogOpen: boolean) => setState({ isDialogOpen })

  const actions = {
    confirmApplePayment,
    confirmStripePayment,
    handleCheckoutSucceeded,
    setIsDialogOpen,
    setSelectedPlanId,
    startCheckout,
    requireSubscription,
  }

  return actions
}
