import type { HandleCheckoutSucceeded } from './createHandleCheckoutSucceeded'
import type { SubscriptionState } from './mapSubscriptionState'

export function createConfirmApplePayment(
  state: SubscriptionState,
  handleCheckoutSucceeded: HandleCheckoutSucceeded,
) {
  return async () => {
    if (state.provider !== 'apple') throw new Error('Provider is not apple')
    if (!state.appAccountToken) throw new Error('No App Account Token')

    const { appAccountToken, selectedPlanId } = state
    if (!selectedPlanId) throw new Error('No plan selected')

    const { startPurchaseIos } = await import('./startPurchaseIos')

    const transactionId = await startPurchaseIos({
      appAccountToken,
      productIdentifier: selectedPlanId,
    })

    await handleCheckoutSucceeded({ provider: 'apple', transactionId })
  }
}
