import type { ComponentProps, JSX } from 'solid-js'
import { mergeProps, splitProps } from 'solid-js'

import animationStyles from '#/components/ui/animation.module.css'

import styles from './Spinner.module.css'

const Spinner = (
  props: {
    size?: number
    centered?: boolean
    style?: JSX.CSSProperties
    hidden?: boolean
    progress?: number
  } & Omit<ComponentProps<'div'>, 'style'>,
) => {
  const [ownProps, rest] = splitProps(props, [
    'size',
    'classList',
    'style',
    'hidden',
    'progress',
  ])
  const ownPropsWithDefaults = mergeProps(
    {
      centered: true,
      hidden: false,
      size: 74,
      progress: 0,
    },
    ownProps,
  )

  return (
    <div
      {...rest}
      classList={{
        [styles.SpinnerContainer]: true,
        [styles.centered]: ownPropsWithDefaults.centered,
        [animationStyles.fadeIn]: true,
        ...ownPropsWithDefaults.classList,
      }}
      style={{
        '--size': `${ownPropsWithDefaults.size}px`,
        ...ownPropsWithDefaults.style,
      }}
      data-hidden={ownPropsWithDefaults.hidden || null}
      data-spinning={ownPropsWithDefaults.progress === 0 || null}
    >
      <svg
        width={ownPropsWithDefaults.size * 3}
        height={ownPropsWithDefaults.size * 3}
        viewBox={`0 0 ${ownPropsWithDefaults.size} ${ownPropsWithDefaults.size}`}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transform: 'rotate(-90deg)' }}
      >
        <circle
          r={(90 / 400) * ownPropsWithDefaults.size}
          cx={ownPropsWithDefaults.size / 2}
          cy={ownPropsWithDefaults.size / 2}
          stroke="#fff"
          stroke-width={ownPropsWithDefaults.size * 0.02}
          stroke-linecap="round"
          stroke-dashoffset={`${
            200 -
            (ownPropsWithDefaults.progress === 0
              ? 0.2
              : ownPropsWithDefaults.progress) *
              100
          }px`}
          fill="transparent"
          stroke-dasharray="200"
        />
      </svg>

      {/* <div class={styles.Spinner} /> */}
    </div>
  )
}

export { Spinner }
