import type { SetStoreFunction } from 'solid-js/store'

import { trpc } from '#/trpc/client'

import type { InitialSubscriptionState } from './initialState'
import type { SubscriptionState } from './mapSubscriptionState'

export function createStartCheckout(
  state: SubscriptionState,
  setState: SetStoreFunction<InitialSubscriptionState>,
  confirmApplePayment: () => Promise<void>,
) {
  return async () => {
    if (!state.selectedPlanId) throw new Error('No plan selected')
    if (!state.provider) throw new Error('No provider selected')

    setState({ isMutationLoading: true })

    const checkoutInfo = await trpc.startCheckout.mutate(
      state.provider === 'apple'
        ? { provider: 'apple' }
        : { provider: 'stripe', planId: state.selectedPlanId },
    )

    setState({ ...checkoutInfo, isMutationLoading: false })

    if (checkoutInfo.provider === 'apple') {
      await confirmApplePayment()
    }
  }
}
