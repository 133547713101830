import { EventEmitter } from 'eventemitter3'
import { createEffect } from 'solid-js'
import type TypedEventEmitter from 'typed-emitter'

import type { TokenStore } from './createTokenStore'

const createWaitForAuthentication = (tokenState: TokenStore[0]) => {
  const eventEmitter = new EventEmitter() as unknown as TypedEventEmitter<{
    ready: () => void
  }>

  createEffect(() => {
    if (tokenState.accessToken) eventEmitter.emit('ready')
  })

  const waitForAuthentication = async () => {
    if (tokenState.accessToken) return
    return new Promise<void>((resolve) =>
      eventEmitter.once('ready', () => resolve()),
    )
  }

  return waitForAuthentication
}

export { createWaitForAuthentication }
