import type { JSX } from 'solid-js'

import { Dialog } from '#/components/Dialog/Dialog'
import { createModule } from '#/utils/module/createModule'

import { SubscriptionDialogContent } from '../ui/SubscriptionDialogContent'
import { createSubscriptionActions } from './createSubscriptionActions'
import { initialSubscriptionState } from './initialState'
import { mapSubscriptionState } from './mapSubscriptionState'

const {
  ContextProvider: SubscriptionBaseProvider,
  useActions: useSubscriptionActions,
  useState: useSubscriptionState,
  useEvent: useSubscriptionEvent,
  useContext: useSubscription,
} = createModule({
  initialState: initialSubscriptionState,
  log: console.log,
  createActions: createSubscriptionActions,
  mapState: mapSubscriptionState,
  name: 'subscription',
})

const SubscriptionDialog = () => {
  const [subscription, { setIsDialogOpen }] = useSubscription()

  return (
    <Dialog
      onOpenChange={setIsDialogOpen}
      isLoading={subscription.isLoading}
      isOpen={subscription.isDialogOpen}
      content={<SubscriptionDialogContent />}
      title="Start subscription"
    />
  )
}

const SubscriptionProvider = (props: { children: JSX.Element }) => {
  return (
    <SubscriptionBaseProvider>
      <SubscriptionDialog />
      {props.children}
    </SubscriptionBaseProvider>
  )
}

export {
  SubscriptionProvider,
  useSubscription,
  useSubscriptionActions,
  useSubscriptionEvent,
  useSubscriptionState,
}
