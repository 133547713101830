import type { User } from '#/db/types/User'

const hasActiveSubscriptionUntil = (subscription: User['subscription']) => {
  return subscription &&
    'currentPeriodStartsAt' in subscription &&
    subscription.currentPeriodStartsAt < new Date() &&
    subscription.currentPeriodEndsAt > new Date()
    ? subscription.currentPeriodEndsAt
    : undefined
}

export { hasActiveSubscriptionUntil }
