import type { StripeElements } from '@stripe/stripe-js'
import type { SetStoreFunction } from 'solid-js/store'

import type { HandleCheckoutSucceeded } from './createHandleCheckoutSucceeded'
import type { InitialSubscriptionState } from './initialState'
import type { SubscriptionState } from './mapSubscriptionState'

export function createConfirmStripePayment(
  state: SubscriptionState,
  setState: SetStoreFunction<InitialSubscriptionState>,
  handleCheckoutSucceeded: HandleCheckoutSucceeded,
) {
  return async (elements: StripeElements) => {
    if (state.provider !== 'stripe') throw new Error('Provider is not stripe')
    if (!state.stripe) throw new Error('Stripe not loaded')
    if (!state.subscriptionId) throw new Error('Subscription Id not set')

    setState({ isMutationLoading: true })

    const result = await state.stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: `${location.origin}/subscription/success?subscriptionId=${state.subscriptionId}&returnRoute=${location.pathname}`,
      },
    })

    if (result.error) {
      console.log('payment failed', result.error)
    } else {
      await handleCheckoutSucceeded({
        provider: 'stripe',
        paymentIntentId: result.paymentIntent.id,
        subscriptionId: state.subscriptionId,
      })
    }
    setState({ isMutationLoading: false })
    return result
  }
}
