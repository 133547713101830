import type { Accessor } from 'solid-js'
import { createEffect } from 'solid-js'
import type { SetStoreFunction, Store } from 'solid-js/store'

import { createActionEventEmitter } from './createActionEventEmitter'
import { createMappedState } from './createMappedState'
import { createReduxDevtoolsStoreSync } from './createReduxDevtoolsSyncedStore'

const createModuleContextValue = <
  S extends object,
  M extends object,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  A extends Record<string, (...args: any[]) => unknown>,
>({
  createActions,
  store: [state, setState],
  mapState,
  propState,
  name,
  log,
}: {
  store: [get: Store<S>, set: SetStoreFunction<S>]
  propState: { state?: Partial<S> }
  mapState: (state: S) => Accessor<M>
  createActions: (mappedState: M, setState: SetStoreFunction<S>) => A
  name: string
  log?: typeof console.log
}) => {
  createEffect(() => {
    if (propState?.state) {
      setState((state) => ({ ...state, ...propState.state }))
    }
  })

  const mappedState = createMappedState(state, mapState)

  const patchedSetState = createReduxDevtoolsStoreSync(
    mappedState,
    setState,
    name,
    log,
  )

  const actions = createActions(mappedState, patchedSetState)

  const { useEvent, useEventOnce, eventEmitter } = createActionEventEmitter<A>()

  Object.entries(actions).forEach(([key, action]) => {
    const patchedAction = (...args: unknown[]) => {
      eventEmitter.emit(
        `before${`${key.charAt(0).toUpperCase()}${key.slice(1)}` as Capitalize<typeof key>}`,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(args as any),
      )
      const result = action(...args)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      eventEmitter.emit(key, ...(args as any))
      return result
    }

    Object.assign(actions, { [key]: patchedAction })
  })

  return [mappedState, actions, { useEvent, useEventOnce }] as const
}

export { createModuleContextValue }
