import { authClient } from '../../authClient'
import { authLogger } from '../../authLogger'
import type { createResetState } from './createResetState'

export function createLogout({
  setLoading,
  resetState,
}: {
  setLoading: () => void
  resetState: ReturnType<typeof createResetState>
}) {
  return async () => {
    authLogger.info('Logging out...')

    setLoading()

    try {
      await authClient.logout.mutate()
    } catch (error) {
      authLogger.error('Error whilelogging out:', error)
    }

    resetState()
  }
}
