import { createTRPCProxyClient, httpLink } from '@trpc/client'
import SuperJSON from 'superjson'

import type { AuthRouter } from './authServer'

export const authClient = createTRPCProxyClient<AuthRouter>({
  links: [
    httpLink({
      url: '/api/auth',
    }),
  ],
  transformer: SuperJSON,
})
