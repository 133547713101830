import { loadStripe } from '@stripe/stripe-js'
import { createEffect, createMemo, createResource, onMount } from 'solid-js'

import { trpc } from '#/trpc/client'
import { useCurrentDate } from '#/utils/useCurrentDate'

import { hasActiveSubscriptionUntil } from '../util/hasActiveSubscription'
import type { InitialSubscriptionState } from './initialState'

export type SubscriptionState = ReturnType<
  ReturnType<typeof mapSubscriptionState>
>

export const mapSubscriptionState = (state: InitialSubscriptionState) => {
  const updateIntervalDate = useCurrentDate(60 * 60 * 1000)
  const hasActiveSubscription = () => {
    updateIntervalDate()

    return state.isSubscriptionLoading
      ? undefined
      : !!hasActiveSubscriptionUntil(state.subscription)
  }

  const [getPlans, { refetch: loadPlans }] = createResource(
    () => [hasActiveSubscription(), state.provider] as const,
    async ([hasActiveSubscription, provider]) => {
      if (hasActiveSubscription === false && provider) {
        return trpc.getPlans.query({ provider })
      }
    },
    { ssrLoadFrom: 'initial', initialValue: undefined },
  )

  onMount(() => loadPlans())

  const [getStripe, { refetch: fetchStripe }] = createResource(
    () => [state.provider],
    ([provider]) =>
      provider === 'stripe'
        ? loadStripe(
            'pk_test_51Pt5AfRob390Yc5yoRnHlFMOXxQQXmCe0gxfvlcBY8bHTOh43O67O8GyQ3H0UAXK9Rb2YhVLp9gyeLBKYTAji7Qf00pRkxrmnA',
          )
        : undefined,
    { ssrLoadFrom: 'initial', initialValue: undefined },
  )

  createEffect(() => {
    if (hasActiveSubscription() === false) {
      fetchStripe()
    }
  })

  const memoizedState = createMemo(() => {
    return {
      ...state,
      selectedPlanId: state.selectedPlanId ?? getPlans()?.[0]?.id,
      plans: getPlans(),
      stripe: getStripe(),
      isLoading: getPlans.loading || getStripe.loading,
      hasActiveSubscription: hasActiveSubscription(),
    }
  })

  return memoizedState
}
