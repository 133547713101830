/* eslint-disable @typescript-eslint/no-explicit-any */

import { onMount } from 'solid-js'
import type { SetStoreFunction, Store } from 'solid-js/store'

import { enhanceSetState } from './enhanceSetState'
import { commonObjectPathAndValue } from './flattenedObjectKeys'

function createReduxDevtoolsStoreSync<
  T extends object = object,
  S extends object = object,
>(
  state: Store<T>,
  setState: SetStoreFunction<S>,
  name?: string,
  log?: typeof console.log,
): SetStoreFunction<S> {
  let devTools:
    | {
        subscribe: (callback: (message: any) => void) => void
        init: (state: any) => void
        send: (action: any, state: any) => void
      }
    | undefined

  onMount(() => {
    devTools = (window as any).__REDUX_DEVTOOLS_EXTENSION__?.connect({
      name,
    })

    devTools?.subscribe((message: any) => {
      if (message.type === 'DISPATCH' && message.state) {
        console.log('DevTools requested to change the state to', message.state)
      }
    })
    devTools?.init(state)
  })

  const enhancedSetState = enhanceSetState(setState, (update) => {
    if (!devTools && !log) {
      return
    }

    const { commonPathString, commonPathValue } =
      commonObjectPathAndValue(update)

    if (log) {
      log(`set(${commonPathString})`, commonPathValue)
    }

    if (devTools) {
      try {
        devTools.send(
          { type: `set(${commonPathString})`, value: commonPathValue },
          { ...state },
        )
      } catch {
        // ignore
      }
    }
  })

  return enhancedSetState
}

export { createReduxDevtoolsStoreSync }
