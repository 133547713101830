import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'

import { google as googleConfig } from '#/auth/config'
import { getPlatform } from '#/utils/getPlatform'

import { authClient } from '../../authClient'
import { authLogger } from '../../authLogger'
import type { createHandleToken } from './createHandleToken'
import type { createResetState } from './createResetState'

export function createSignInWithGoogle({
  setLoading,
  handleToken,
  resetState,
}: {
  handleToken: ReturnType<typeof createHandleToken>
  setLoading: () => void
  resetState: ReturnType<typeof createResetState>
}) {
  const initializePromise = GoogleAuth.initialize({
    clientId:
      googleConfig.clientId[
        getPlatform() === 'android' ? 'web' : getPlatform()
      ],
    scopes: ['profile', 'email'],
  })

  return async () => {
    setLoading()

    try {
      await initializePromise

      await GoogleAuth.signOut()
      const result = await GoogleAuth.signIn()

      const {
        authentication: { idToken },
        familyName,
        givenName,
        email,
      } = result

      const { accessToken, expirationTime, issuedAt } =
        await authClient.loginWithGoogle.mutate({
          idToken,
          familyName: familyName || undefined,
          givenName: givenName || undefined,
          email: email || undefined,
          platform: getPlatform(),
        })

      authLogger.info('Successfully logged in with Google', result)

      handleToken({ accessToken, expirationTime, issuedAt })
    } catch (error) {
      authLogger.error('Error while logging in with Google', error)
      resetState()
    }
  }
}

// const isIos = () => {
//   return (
//     [
//       'iPad Simulator',
//       'iPhone Simulator',
//       'iPod Simulator',
//       'iPad',
//       'iPhone',
//       'iPod',
//     ].includes(navigator.platform) ||
//     // iPad on iOS 13 detection
//     (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
//   )
// }
