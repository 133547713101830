import { createStore } from 'solid-js/store'

export type TokenStore = ReturnType<typeof createTokenStore>

export function createTokenStore() {
  const [getState, setState] = createStore({
    isLoading: true,
    accessToken: undefined as string | undefined,
    expirationTime: undefined as Date | undefined,
    issuedAt: undefined as Date | undefined,
  })

  return [getState, setState] as const
}
