import { Button } from '#/components/ui/Button'

import { useAuth } from './AuthProvider'

const SignInWithAppleButton = () => {
  const auth = useAuth()
  return <Button onClick={auth.signInWithApple}>Sign In With Apple</Button>
}

export { SignInWithAppleButton }
