import { createEffect, onMount } from 'solid-js'

import type { TokenStore } from './createTokenStore'

export function createAccessTokenRefreshTimer({
  tokenState,
  refreshAccessToken,
}: {
  tokenState: TokenStore[0]
  refreshAccessToken: () => void
}) {
  createEffect(() => {
    const { expirationTime, issuedAt, isLoading } = tokenState

    if (!expirationTime || !issuedAt || isLoading) return

    const interval = setInterval(() => {
      const secondsUntilTokenExpires = Math.round(
        (expirationTime?.getTime() - Date.now()) / 1000,
      )

      const lifetimePassed =
        (Date.now() - issuedAt?.getTime()) /
        (expirationTime.getTime() - issuedAt?.getTime())

      if (lifetimePassed > 0.5 && secondsUntilTokenExpires < 290) {
        clearInterval(interval)
        refreshAccessToken()
      }
    }, 1000)

    return () => clearInterval(interval)
  })

  onMount(() => {
    refreshAccessToken()
  })
}
