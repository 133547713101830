import { EventEmitter } from 'eventemitter3'
import { createEffect, createSignal } from 'solid-js'
import type { SetStoreFunction } from 'solid-js/store'
import type TypedEventEmitter from 'typed-emitter'

import type { InitialSubscriptionState } from './initialState'
import type { SubscriptionState } from './mapSubscriptionState'

const createRequireSubscription = (
  subscriptionState: SubscriptionState,
  setState: SetStoreFunction<InitialSubscriptionState>,
) => {
  const eventEmitter = new EventEmitter() as unknown as TypedEventEmitter<{
    ready: () => void
    failed: () => void
  }>

  const [wasOpen, setWasOpen] = createSignal(false)

  createEffect(() => {
    if (subscriptionState.isDialogOpen) {
      setWasOpen(true)
    }

    if (subscriptionState.hasActiveSubscription) {
      eventEmitter.emit('ready')
    }

    if (wasOpen() && !subscriptionState.isDialogOpen) {
      setWasOpen(false)
      if (!subscriptionState.hasActiveSubscription) {
        eventEmitter.emit('failed')
      }
    }
  })

  const requireSubscription = async () => {
    if (subscriptionState.hasActiveSubscription) return

    setState({ isDialogOpen: true })

    return new Promise<void>((resolve, reject) => {
      const handleReady = () => {
        resolve()
        eventEmitter.off('failed', handleFailed)
      }

      const handleFailed = () => {
        reject(new Error('User canceled subscription process.'))
        eventEmitter.off('ready', handleReady)
      }

      eventEmitter.once('ready', handleReady)
      eventEmitter.once('failed', handleFailed)
    })
  }

  return requireSubscription
}

export { createRequireSubscription }
