export const apple = {
  clientId: 'com.saztunes.applesignin',
  keyId: '52JNYTZMQG',
  teamId: 'C4U6U4692C',
  alg: 'ES256',
  pkcs8: `-----BEGIN PRIVATE KEY-----
  MIGTAgEAMBMGByqGSM49AgEGCCqGSM49AwEHBHkwdwIBAQQgMqSA/v2zeG0UTCmy
  lSeofxqjlZHTuLTOSjBpR6T9Jm6gCgYIKoZIzj0DAQehRANCAASONX+ErYzUMHFa
  U/n6WDfmm+Uk4twtjBFKIbyjK43XiA15y+oVG8VBbI3IryNQFq+HOgeV3QnGl/hA
  27oGTQbj
  -----END PRIVATE KEY-----`,
}

export const google = {
  clientId: {
    android:
      '995576627900-t9979r2b1g5auj2bm3807srlbadbo3am.apps.googleusercontent.com',
    web: '995576627900-3cfl1jo1vnp9taeh2fn9sg1bojsqesas.apps.googleusercontent.com',
    ios: '995576627900-6d0i237fbq385iuueegbq3q4vf19ehe1.apps.googleusercontent.com',
  },
}

export const token = {
  alg: 'ES256',
  pkcs8: `-----BEGIN PRIVATE KEY-----
    MIGTAgEAMBMGByqGSM49AgEGCCqGSM49AwEHBHkwdwIBAQQgMqSA/v2zeG0UTCmy
    lSeofxqjlZHTuLTOSjBpR6T9Jm6gCgYIKoZIzj0DAQehRANCAASONX+ErYzUMHFa
    U/n6WDfmm+Uk4twtjBFKIbyjK43XiA15y+oVG8VBbI3IryNQFq+HOgeV3QnGl/hA
    27oGTQbj
    -----END PRIVATE KEY-----`,
}

export const authCookiePaths = ['/public', '/api/trpc'] as const

export const accessTokenCookie = 'accessToken'
