import { z } from 'zod'

// NOTE: Be careful to not expose secret values from the server to the client here
const ClientEnvSchema = z.object({
  SITE_URL: z.string().url().default('http://localhost:3000'),
  STACK_NAME: z.string().optional(),
})

const clientEnv = ClientEnvSchema.parse({
  ...import.meta.env,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...(typeof window !== 'undefined' && (window as any).___ENV),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...(typeof process !== 'undefined' && (process as any).env),
})

export { clientEnv }
